export const USER_API_GET_WEBSITE_CONFIG = `api/user/websiteConfig`;
export const USER_API_GET_SINGUP_CONFIG = `api/user/singupConfig`;

export const USER_API_SINGIN = `api/auth/singin`;
export const USER_API_SINGUP = `api/auth/singup`;

export const USER_API_VERIFY_MAIL_IN_DB = `api/auth/verifyMailInDb`;
export const USER_API_VERIFY_ACCOUNT = `api/auth/verifyAccount`;
export const USER_API_FORGET_PASSWORD = 'api/auth/forgetPassword';


export const USER_API_LOGOUT = `api/auth/logout`;
export const USER_API_ISLOGGEDIN = `api/auth/getIsLoggedIn`;

export const USER_API_VERIFY_TOKEN_RESET_PASSWORD = `api/auth/verifytokenResetPassword`;
export const USER_API_RESET_PASSWORD = `api/auth/resetPassword`;

export const API_USER_FILEPV = 'api/user/filePv';

export const API_USER_GETCAROUSELELEMENTS = 'api/user/getCarouselElements'

export const SUPERADMIN_API_GETSERVERDATA = `api/superadmin/getServerData`;
export const SUPERADMIN_API_SETTHEME = `api/superadmin/setTheme`;

export const SUPERADMIN_API_SETLOGO = `api/superadmin/setLogo`;
export const SUPERADMIN_API_SETPICTURE = `api/superadmin/setPicture`;
export const SUPERADMIN_API_SETPICTURECAROUSEL = `api/superadmin/setPictureCarousel`;

export const SUPERADMIN_API_GETALLCAROUSEL = `api/superadmin/getAllCarousel`;
export const SUPERADMIN_API_CHANGEETATELEMENTCAROUSEL = 'api/superadmin/changeEtatElementCarousel'


export const SUPERADMIN_API_SETTEXTPRESIDENT = `api/superadmin/setTextPresident`;
export const SUPERADMIN_API_REMOVETEXTPRESIDENT = `api/superadmin/removeTextPresident`;
export const SUPERADMIN_API_SETSOCIALMEDIAS = 'api/superadmin/setSocialMedias';
export const SUPERADMIN_API_SETCONTACTINFOS = 'api/superadmin/setContactInfos';
export const SUPERADMIN_API_STESPONSORSCAT = 'api/superadmin/setSponsorsCat';
export const SUPERADMIN_API_STESPONSORS = 'api/superadmin/setSponsors';
export const SUPERADMIN_API_SETPARTNER = 'api/superadmin/setPartner';
export const SUPERADMIN_API_STETHEMES = 'api/superadmin/setThemesLanding';
export const SUPERADMIN_API_STETEAMCAT = 'api/superadmin/setTeamCat';
export const SUPERADMIN_API_SETTEAM = 'api/superadmin/setTeam';
export const SUPERADMIN_API_SETINFOSBAR = 'api/superadmin/setInfosBar';
export const SUPERADMIN_API_SETPACK = 'api/superadmin/setPack';
export const SUPERADMIN_API_SETPACKCAT = 'api/superadmin/setPackCat';
export const SUPERADMIN_API_SETPAYEMENTLIST = 'api/superadmin/setPayementList';
export const SUPERADMIN_API_STEPAYEMENTCAT = 'api/superadmin/setPayementCat';

export const SUPERADMIN_API_SETSINGUPCONFIG = 'api/superadmin/setSingupConfig';

export const SUPERADMIN_API_GETPGMDAY = 'api/superadmin/getPgmDay';
export const SUPERADMIN_API_ADDPGMDAY = 'api/superadmin/addPgmDay';
export const SUPERADMIN_API_EDITPGMDAY = 'api/superadmin/editPgmDay';
export const SUPERADMIN_API_DELETEPGMDAY = 'api/superadmin/deletePgmDay';

export const SUPERADMIN_API_GETPGMROOM = 'api/superadmin/getPgmRoom';
export const SUPERADMIN_API_ADDPGMROOM = 'api/superadmin/addPgmRoom';
export const SUPERADMIN_API_EDITPGMROOM = 'api/superadmin/editPgmRoom';
export const SUPERADMIN_API_DELETEPGMROOM = 'api/superadmin/deletePgmRoom';


export const SUPERADMIN_API_GETPGMITEM = 'api/superadmin/getPgmItem';
export const SUPERADMIN_API_ADDPGMITEM = 'api/superadmin/addPgmItem';
export const SUPERADMIN_API_EDITPGMITEM = 'api/superadmin/editPgmItem';
export const SUPERADMIN_API_DELETEPGMITEM = 'api/superadmin/deletePgmItem';
export const SUPERADMIN_API_GETDAYROOM = 'api/superadmin/getDayRoom';


export const SUPERADMIN_API_GETABSTRACTCAT = 'api/superadmin/getAbstractCat';
export const SUPERADMIN_API_SETABSTRACTCAT = 'api/superadmin/setAbstractCat';
export const SUPERADMIN_API_EDITABSTRACTCAT = 'api/superadmin/editAbstractCat';
export const SUPERADMIN_API_DELETEABSTRACTCAT = 'api/superadmin/deleteAbstractCat';


export const SUPERADMIN_API_GETABSTRACT = 'api/superadmin/getAbstract';
export const SUPERADMIN_API_ADDABSTRACT = 'api/superadmin/addAbstract';
export const SUPERADMIN_API_EDITABSTRACT = 'api/superadmin/editAbstract';
export const SUPERADMIN_API_DELETEABSTRACT = 'api/superadmin/deleteAbstract';


export const API_USER_GETPGMITEMS = `api/user/getPgmItems`;
export const API_USER_GETPGMDAY = `api/user/getPgmDay`;
export const API_USER_GETPGMROOM = `api/user/getPgmRoom`;
export const API_USER_GETPGMITEM = `api/user/getPgmItem`;

export const API_USER_GETABSTRACTCAT = `api/user/getAbstactCat`;
export const API_USER_GETABSTRACTBYCAT = `api/user/getAbstactByCat`;


export const API_ADMIN_GET_ALL_USERS = 'api/admin/getAllUsers';
export const API_ADMIN_CHANGE_ACCOUNT_TYPE='api/admin/changeAccountType';
export const API_ADMIN_CHANGE_ACCOUNT_VERIFIED='api/admin/changeAccountVerified';
export const API_ADMIN_CHANGE_ACCOUNT_PAYEMENT='api/admin/changeAccountPayement';


export const ADMIN_API_SINGUP = `api/admin/singup`;
export const API_GET_MEDIAS = `medias-mep/`;

export const SOCKET_URL = `/sio`;


