import React, { useState, useEffect } from 'react';

import { NavLink } from 'react-router-dom';

import { Menu, Image, Drawer, message } from 'antd';

import { useIsLoggedIn, usePlateformConfig } from '../../reduxhooks/ReduxHooks';

import { useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import { USER_API_LOGOUT } from '../../config';
import axios from 'axios';

import { useDispatch } from 'react-redux';
import { isLogged } from '../../reducers/slices/isLoggedIn.slice';


import logoDesktop from '../../assets/medias/logo/logoDesktop.png';
import logoMobile from '../../assets/medias/logo/logoMobile.png';

import socket from '../../socket/Socket';

import MenuItems from './MenuItems';

import { API_GET_MEDIAS } from '../../config';

export default function MenuLandingPagePrin() {

  const [messageApi, contextHolderMessage] = message.useMessage();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { menuConnected, menuNotConnected } = MenuItems();

  const { t } = useTranslation();

  const [openMenu, setOpenMenu] = useState(false);
  const [selectedKey, setSelectedKey] = useState('home')

  const loggedIn = useIsLoggedIn();
  const plateformConfig = usePlateformConfig();

  const key = 'updatable';

  const [items, setItems] = useState([]);

  useEffect(() => {

    if(loggedIn.logged) {
      setItems(menuConnected)
    } else {
      setItems(menuNotConnected)
    }

  }, [loggedIn.logged]) 

  const LogoMainDesktop = () => {
    return (
      <div className='container-logo'>
        <NavLink to='/' className='logo' >
          <Image src={plateformConfig.logoDesktop ? `${API_GET_MEDIAS}`+'logo/logoDesktop/'+plateformConfig.logoDesktop : logoDesktop} alt='Logo' preview={false} className='logo-picture' />
        </NavLink >
      </div>
    )
  }

  return (
    <div >
      <LogoMainDesktop />
    </div>
  )
}
