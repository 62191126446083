


import React, { useState, useEffect } from 'react';
import { Layout } from 'antd';

import { Outlet } from "react-router-dom"


import MenuLandingPagePrin from '../components/menu/MenuLandingPagePrin';

import { useSelector } from 'react-redux';
import { statesiteConfig } from '../reducers/slices/siteConfig.slice';
import InfosBars from '../components/infosBars/InfosBars';
import { usePlateformConfig } from '../reduxhooks/ReduxHooks';



export default function AppLayout({ children, logoUrl }) {

  const plateformConfig = usePlateformConfig();

  const { Header, Content, Footer } = Layout;

  const configValue = useSelector(statesiteConfig);

  const year = new Date().getFullYear()



  const [scrollInfoTop, setScrollInfoTop] = useState(true);
  const firstHeaderHeight = 0;


  useEffect(() => {

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScroll = () => {
    const scrollTop = window.scrollY || document.documentElement.scrollTop;
    setScrollInfoTop(scrollTop);
  };

  return (



    <Layout style={{ display: 'flex' }}>

      <Header
        className="header-menu"
        style={{ marginTop: scrollInfoTop < 200 ? `${firstHeaderHeight}px` : 0 }}
      >
        <MenuLandingPagePrin />

      </Header>

      <Content>

        <Outlet />

      </Content>


      <Footer >
        &copy; Copyright My Sotcot {year} . All Rights Reserved
      </Footer>
    </Layout>


  )
}
